import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { DateTimePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import Editor from "ckeditor5-custom-inline/build/ckeditor";
import Editor from "ckeditor5-custom-classic/build/ckeditor";
import PersonSelector from "./PersonSelector";
import TitleSelector from "./TitleSelector";
import PersonTypeSelector from "./PersonTypeSelector";
import Autocomplete from "../autocomplete/Autocomplete";
import MemberTermsEditor from "../member/MemberTermsEditor";

function EditPersonPopup(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    //const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [person, setPerson] = useState(null);
    const [page, setPage] = useState("details");

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    function loadData() {
        setError(null)
        setData(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getPerson + "/" + person.id)
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function saveData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getPerson + "/" + data.id, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    function addData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .post(authConfig.api.urls.getPerson, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }
    const handleClose = () => {
        setShow(false);
        setData(null);
    }
    const handleShow = () => {
        setShow(true);
        if (props.addnew) {
            setData({
                id: uuidv4(),
                attributes: [],
                title: "",
                firstname: "",
                lastname: "",
                member: null,
                type: "",
                email: "",
                outsideBody: [],
                attributes:[]
            });
        } else {
            loadData();
        }

    }
    const handleSave = () => {
        if (props.addnew) {
            addData();
            handleClose();
        } else {
            saveData();
        }
        
    }

    const handleSelectPerson = (o) => {
        setPerson(o);
    }

    const handleTitleChange = (o) => {
        data.title = o;
        setData({ ...data });
    }
    const handleFirstnameChange = (e) => {
        data.firstname = e.target.value;
        setData({ ...data });
    }
    const handleLastnameChange = (e) => {
        data.lastname = e.target.value;
        setData({ ...data });
    }
    const handleTypeChange = (o) => {
        if (o.toLowerCase() === "member" && data.member === null) {
            data.member = {
                "id": uuidv4(),
                "titleEn": "",
                "titleCy": "",
                "appointingAuthorityEn": "",
                "appointingAuthorityCy": "",
                "photo": null,
                "descriptionEn": "",
                "descriptionCy": "",
                "giftsAndHospitalities": [],
                "terms": [],
                "isCurrent": true,
                "person": {}
            }
        }
        data.type = o;
        setData({ ...data });
    }
    const handleEmailChange = (e) => {
        data.email = e.target.value;
        setData({ ...data });
    }

    const handlePageChange = (p) => {
        setPage(p);
    }

    /*
     * 
     * */
    const handleAppointingAuthorityEnChange = (o) => {
        data.member.appointingAuthorityEn = o.en;
        if (o.cy) {
            data.member.appointingAuthorityCy = o.cy;
        }
        setData({ ...data });
    }
    const handleAppointingAuthorityCyChange = (e) => {
        data.member.appointingAuthorityCy = e.target.value;
        setData({ ...data });
    }
    const handleMemberTitleEnChange = (o) => {
        data.member.titleEn = o.en;
        if (o.cy) {
            data.member.titleCy = o.cy;
        }
        setData({ ...data });
    }
    const handleMemberTitleCyChange = (e) => {
        data.member.titleCy = e.target.value;
        setData({ ...data });
    }
    const handleDescriptionChange = (html, lang) => {
        if (lang === "en") { data.member.descriptionEn = html; }
        if (lang === "cy") { data.member.descriptionCy = html; }
        setData({ ...data });
    }

    /*
     * 
     * */
    async function handleUpload(e) {
        if (e.target.value) {
            const formdata = new FormData();
            formdata.append('file', e.target.files[0]);
            formdata.append('id', uuidv4());
            console.log(e.target.files[0].type);
            if (e.target.files[0].type === ("image/jpeg")) {
                console.log(e.target.files[0]);
                let authresponse = null;
                //setLoading(true);
                if (isAuthenticated) {
                    if (account) {
                        //setLoading(true)
                        await instance
                            .acquireTokenSilent({
                                scopes: authConfig.api.scopes,
                                account: account
                            })
                            .then(response => {
                                authresponse = response;
                            })
                            .catch(error => {
                                //setError("Error getting token: " + error.message)
                                //console.error(error)
                                //setLoading(false);
                            });
                    }
                }
                (async () => {
                    let headers = new Headers();
                    if (authresponse) {
                        headers.append('Authorization', 'Bearer ' + authresponse.accessToken);
                    }
                    const response = await fetch(authConfig.api.urls.getMemberUploadFile, {
                        method: "post",
                        headers,/*headers: { 'Content-Type': 'multipart/form-data' },*/
                        body: formdata
                    });
                    if (response.ok) {
                        const jsonData = await response.json();
                        addFile(jsonData);
                    }


                })();
            }

            e.target.value = null;
        }
    }
    const addFile = (attachmentjson) => {
        data.member.photo = attachmentjson
        setData({ ...data });
    }
    const handleTermsChange = (o) => {
        data.member.terms = o;
        setData({ ...data });
        console.log(data);
    }
    const handleIsCurrentChange = (e) => {
        data.member.isCurrent = e.target.checked;
        setData({ ...data });
    }
    /*
     * 
     * */
    return (
        <>
            {props.addnew ?
                (
                    <Button variant="success" onClick={handleShow}>
                        Add person
                    </Button>
                ) :
                (
                    <Row>
                        <Col xs="10">
                            <PersonSelector value={person} onChange={handleSelectPerson} />
                        </Col>
                        <Col xs="2">
                            <Button variant="success" onClick={handleShow} disabled={false}>
                                Edit
                            </Button>
                        </Col>
                    </Row >)
            }


            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.addnew ? "Add New Person" : "Edit Person"} - {data == null ? '' : data.title + ' ' + data.firstname + ' ' + data.lastname}</Modal.Title>
                </Modal.Header>
                {data == null ? '' :
                    (
                        <Modal.Body>
                            <Row>
                                <Col xs="3">
                                    Title
                                </Col>
                                <Col>
                                    <TitleSelector value={data.title} onChange={handleTitleChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    Firstname
                                </Col>
                                <Col>
                                    <Form.Control type="text" value={data.firstname} onChange={handleFirstnameChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    Lastname
                                </Col>
                                <Col>
                                    <Form.Control type="text" value={data.lastname} onChange={handleLastnameChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    Type
                                </Col>
                                <Col>
                                    <PersonTypeSelector value={data.type} onChange={handleTypeChange} disabled={data.type.toLowerCase() === "member"} />
                                    {data.member === null ? '' : (
                                        <Form.Check 
                                            type="switch"
                                            id="iscurrent-switch"
                                            label={data.member.isCurrent ? "Current Member" : "Former Member"}
                                            checked={data.member.isCurrent}
                                            onChange={handleIsCurrentChange}
                                        />
                                        )}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    Email
                                </Col>
                                <Col>
                                    <Form.Control type="email" value={data.email} onChange={handleEmailChange} />
                                </Col>
                            </Row>

                            {data.member ? (
                                <div style={{ display: data.type.toLowerCase() === "member" ? '' : 'none' }}>
                                    <ButtonGroup className="mb-2">
                                        <ToggleButton
                                            key={1}
                                            id={`fieldtype-1`}
                                            type="radio"
                                            name="radio"
                                            value="details"
                                            checked={page === "details"}
                                            onClick={() => { handlePageChange("details"); }}
                                            size="sm"
                                            variant="success"
                                        >
                                            Member Details
                                        </ToggleButton>
                                        <ToggleButton
                                            key={2}
                                            id={`fieldtype-2`}
                                            type="radio"
                                            name="fieldtype"
                                            value="description"
                                            checked={page === "description"}
                                            onClick={() => { handlePageChange("description"); }}
                                            size="sm"
                                            variant="success"
                                        >
                                            Description
                                        </ToggleButton>
                                        <ToggleButton
                                            key={3}
                                            id={`fieldtype-3`}
                                            type="radio"
                                            name="fieldtype"
                                            value="terms"
                                            checked={page === "terms"}
                                            onClick={() => { handlePageChange("terms"); }}
                                            size="sm"
                                            variant="success"
                                        >
                                            Terms
                                        </ToggleButton>
                                        <ToggleButton
                                            key={4}
                                            id={`fieldtype-4`}
                                            type="radio"
                                            name="fieldtype"
                                            value="photo"
                                            checked={page === "photo"}
                                            onClick={() => { handlePageChange("photo"); }}
                                            size="sm"
                                            variant="success"
                                        >
                                            Photo
                                        </ToggleButton>
                                    </ButtonGroup>
                                    &nbsp;
                                    <ButtonGroup className="mb-2">
                                        <ToggleButton
                                            key={1}
                                            id={`fieldlang-1`}
                                            type="radio"
                                            name="fieldlang"
                                            value="en"
                                            checked={language === "en"}
                                            onClick={() => { handleLanguageChange("en"); }}
                                            size="sm"
                                            variant="success"
                                        >
                                            English
                                        </ToggleButton>
                                        <ToggleButton
                                            key={2}
                                            id={`fieldlang-2`}
                                            type="radio"
                                            name="fieldlang"
                                            value="cy"
                                            checked={language === "cy"}
                                            onClick={() => { handleLanguageChange("cy"); }}
                                            size="sm"
                                            variant="success"
                                        >
                                            Cymraeg
                                        </ToggleButton>
                                    </ButtonGroup>
                                    <Container style={{ display: language === 'cy' ? "none" : "" }}>
                                        <div style={{ display: page === "details" ? '' : 'none' }}>
                                            <Row>
                                                <Col xs="4">
                                                    Member Title (en)
                                                </Col>
                                                <Col xs="8">
                                                    <Autocomplete
                                                        url={authConfig.api.urls.getMemberTitleLookup}
                                                        value={data.member.titleEn}
                                                        onChange={handleMemberTitleEnChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="4">
                                                    Appointing Authority (en)
                                                </Col>
                                                <Col xs="8">
                                                    <Autocomplete
                                                        url={authConfig.api.urls.getMemberAppointingAuthorityLookup}
                                                        value={data.member.appointingAuthorityEn}
                                                        onChange={handleAppointingAuthorityEnChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{ display: page === "description" ? '' : 'none' }}>
                                            <CKEditor
                                                editor={Editor}
                                                data={data.member.descriptionEn}
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    handleDescriptionChange(data, 'en');
                                                    //console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    //console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    //console.log('Focus.', editor);
                                                }}
                                            />
                                        </div>
                                    </Container>
                                    <Container style={{ display: language === 'cy' ? "" : "none" }}>
                                        <div style={{ display: page === "details" ? '' : 'none' }}>
                                            <Row>
                                                <Col xs="4">
                                                    Member Title (cy)
                                                </Col>
                                                <Col xs="8">
                                                    <Form.Control type="text"
                                                        value={data.member.titleCy}
                                                        onChange={handleMemberTitleCyChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="4">
                                                    Appointing Authority (cy)
                                                </Col>
                                                <Col xs="8">
                                                    <Form.Control type="text"
                                                        value={data.member.appointingAuthorityCy}
                                                        onChange={handleAppointingAuthorityCyChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{ display: page === "description" ? '' : 'none' }}>
                                            <CKEditor
                                                editor={Editor}
                                                data={data.member.descriptionCy}
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    handleDescriptionChange(data, 'cy');
                                                    //console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    //console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    //console.log('Focus.', editor);
                                                }}
                                            />
                                        </div>
                                    </Container>
                                    <div style={{ display: page === "terms" ? '' : 'none' }}>
                                        <MemberTermsEditor value={data.member.terms} onChange={handleTermsChange} />
                                    </div>
                                    <div style={{ display: page === "photo" ? '' : 'none' }}>
                                        <Form.Group controlId="formFileMultiple" className="mb-3">
                                            <Form.Label>Set member photo (.jpg)</Form.Label>
                                            <Form.Control type="file" accept=".jpg" onChange={handleUpload} />
                                        </Form.Group>
                                        {data.member.photo ? (
                                            <img
                                                style={{ maxHeight: "280px" }}
                                                src={authConfig.api.urls.getMemberPhoto + "/" + data.member.photo.id + "/" + data.member.photo.fileName}
                                            />
                                        ) : ''}

                                    </div>
                                </div>
                            ) : ''}
                        </Modal.Body>
                    )}
                <Modal.Footer>
                    <Button variant="success" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EditPersonPopup;